import React, { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";

import { OPEN_HAMBURGER_MENU, OPEN_SEARCHBAR } from "../../../store/actions";
import { MenuItem } from "../../menu-item";
import HamburgerMenu from "../../../atoms/hamburger-menu";
import AppStore from "../../../atoms/app-store";
import GooglePlay from "../../../atoms/google-play";
import { SvgIconHandler } from "../../../atoms/svg-icon-hadler";
import SocialShareIcons from "../../../molecules/social-share";
import Modal from "../../../atoms/modal";

import "./navbar.m.css";
import throttle from "lodash/throttle";
import MyAccountButton from "../../../atoms/my-account-button";
import HeaderNotification from "../../../atoms/header-notification";
import MyAccount from "../../../atoms/my-account";
import HamburgerAppStore from "../../../atoms/hamburger-app-store";
import HamburgerGooglePlay from "../../../atoms/hamburger-google-play";
import HamuburgerEPaperButton from "../../../atoms/hamburger-ePaper-button";
import HamburgerMyAccountButton from "../../../atoms/hamburger-myAccount-button";
import HamburgerLoginButton from "../../../atoms/login-button";

const NavBar = () => {
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const currentPath = get(qtState, ["currentPath"], null);
  const isHamburgerMenuOpen = get(getState, ["isHamburgerMenuOpen"], false);
  const menu = get(getState, ["qt", "data", "navigationMenu", "homeMenu"], []);
  const primaryMenus = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const secondaryMenus = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const displayStyle = isHamburgerMenuOpen ? "flex" : "none";

  const [navSearchText, setNavSearchText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showPrimaryMenuSection, setShowPrimaryMenuSection] = useState(false);
  const [showSecondaryMenuSection, setShowSecondaryMenuSection] = useState(false);
  const [changeTextColor, setChangeTextColor] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [menuIdx, setMenuIdx] = useState(false);
  const [showModal, setShowModal] = useState("");
  const isUserLogin = useSelector((state) => get(state, ["isLogin"], false));
  const [redirectURL, setRedirectUrl] = useState("");
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  useEffect(() => {
    setRedirectUrl(window.location.href);
  }, []);

  const signinHandler = () => {
    if (window) {
      window.dataLayer.push({
        event: "SSO_Page_Open"
      });
    }
    window.location.href = `${ssoEndpoint}/auth?redirectUrl=${redirectURL}`;
  };

  useEffect(() => {
    const isProfileOpen = window.localStorage.getItem("isProfileOpen");

    setShowModal(isProfileOpen);
  }, [showModal]);
  const openModal = () => {
    setShowModal("true");
    localStorage.setItem("isProfileOpen", "true");
  };
  const closeModal = () => {
    setShowModal("false");
    localStorage.setItem("isProfileOpen", "false");
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (isHamburgerMenuOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    }
  }, [isHamburgerMenuOpen]);

  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen
    });
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
  };

  const navSearchHandler = () => {
    setSearchText(navSearchText);
  };
  const filterMenu = () => {
    if (searchText) {
      return secondaryMenus.filter((item, index) => item.title.includes(searchText));
    }
    return secondaryMenus;
  };

  const showPrimarySectionHandler = (idx) => {
    setShowPrimaryMenuSection(showPrimaryMenuSection === idx ? undefined : idx);
  };
  const showSecondarySectionHandler = (idx) => {
    setShowSecondaryMenuSection(showSecondaryMenuSection === idx ? undefined : idx);
  };

  const changeTextColorHandler = (idx) => {
    console.log("changeTextColorHandler", changeTextColorHandler);
  };

  const menuMouseEnterHandler = (idx) => {
    setOnHover(true);
    setMenuIdx(idx);
  };
  const menuMouseLeaveHandler = (idx) => {
    setOnHover(false);
    setMenuIdx(undefined);
  };
  const getFirstNavbarMenu = (menu) => {
    return (
      <ul styleName="navbar row1 ">
        {secondaryMenus.length > 0 &&
          secondaryMenus.slice(0, 9).map((item, index) => {
            const isColor = item?.data?.color.toLowerCase() !== "#ffffff";
            const isHover = onHover && index === menuIdx;
            return (
              <li
                key={index}
                styleName={index === 6 || index === 4 ? "dropdown activated-ddrow1" : "dropdown ddrow1"}
                onMouseEnter={() => menuMouseEnterHandler(index)}
                onMouseLeave={() => menuMouseLeaveHandler(index)}
              >
                <MenuItem
                  changeTextColor={isColor && !isHover && !(index === 6 || index === 4)}
                  item={item}
                  menuStyle="menu-link"
                  toggleHandler={() =>
                    dispatch({
                      type: OPEN_SEARCHBAR,
                      isSearchBarOpen: false
                    })
                  }
                />
              </li>
            );
          })}
      </ul>
    );
  };

  const getSecondNavbarMenu = () => {
    return (
      <ul styleName="navbar row2">
        {primaryMenus.length > 0 &&
          primaryMenus.slice(0, 9).map((item, index) => {
            const isColor = item?.data?.color.toLowerCase() !== "#ffffff";
            const isHover = onHover && index === menuIdx;
            return (
              <li
                key={index}
                styleName={`dropdown ddrow2 ${item.completeUrl === currentPath ? "activemenu" : ""}`}
                onMouseEnter={() => menuMouseEnterHandler(index)}
                onMouseLeave={() => menuMouseLeaveHandler(index)}
              >
                {/* <span className={index === 1 ? "animated-text" : "flex"}>
                  {index === 1 && (
                    <img src="https://images.assettype.com/prajavani-training/2025-01-31/omgxpyn5/election" />
                  )} */}
                <MenuItem
                  changeTextColor={isColor && !isHover}
                  item={item}
                  menuStyle="menu-link"
                  toggleHandler={() =>
                    dispatch({
                      type: OPEN_SEARCHBAR,
                      isSearchBarOpen: false
                    })
                  }
                />
                {item.children.length > 0 && (
                  <button aria-label="down-arrow-button" styleName="down-arrow" type="submit" key="2">
                    <div styleName="blackarrow">
                      <SvgIconHandler type="blackarrowdropdown" width="10" height="10" />
                    </div>
                    <div styleName="whitearrow">
                      <SvgIconHandler type="whitearrowdropdown" width="10" height="10" />
                    </div>
                  </button>
                )}
                {item.children.length > 0 && (
                  <div styleName="overlay-submenus">
                    <div styleName="triangle"></div>
                    <div styleName="ddsection">
                      <div styleName="ddsec1">
                        {item.children.slice(0, 8).map((subitem, idx) => (
                          <div
                            key={idx}
                            styleName="subitem"
                            style={{
                              color: `${subitem?.data?.color.toLowerCase() !== "#ffffff" ? subitem?.data?.color : ""}`
                            }}
                          >
                            <Link externalLink={subitem.isExternalLink} href={subitem && subitem?.url}>
                              {subitem.title}
                            </Link>
                          </div>
                        ))}
                      </div>
                      {item.children.length >= 8 && (
                        <div styleName="ddsec2">
                          {item.children.slice(8, 16).map((subitem, idx) => (
                            <div
                              key={idx}
                              styleName="subitem"
                              style={{
                                color: `${subitem?.data?.color.toLowerCase() !== "#ffffff" ? subitem?.data?.color : ""}`
                              }}
                            >
                              <Link externalLink={subitem.isExternalLink} href={subitem && subitem?.url}>
                                {subitem.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                      {item.children.length >= 16 && (
                        <div styleName="ddsec3">
                          {item.children.slice(16, 24).map((subitem, idx) => (
                            <div
                              key={idx}
                              styleName="subitem"
                              style={{
                                color: `${subitem?.data?.color.toLowerCase() !== "#ffffff" ? subitem?.data?.color : ""}`
                              }}
                            >
                              <Link key={index} externalLink={subitem.isExternalLink} href={subitem && subitem?.url}>
                                {subitem.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                      {item.children.length >= 24 && (
                        <div styleName="ddsec4">
                          {item.children.slice(24, 32).map((subitem, idx) => (
                            <div
                              key={idx}
                              styleName="subitem"
                              style={{
                                color: `${subitem?.data?.color.toLowerCase() !== "#ffffff" ? subitem?.data?.color : ""}`
                              }}
                            >
                              <Link key={index} externalLink={subitem.isExternalLink} href={subitem && subitem?.url}>
                                {subitem.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* </span> */}
              </li>
            );
          })}
      </ul>
    );
  };

  const getDropdownList = () => {
    if (!isHamburgerMenuOpen) {
      return null;
    }

    const navigateToPage = (url) => global.app.navigateToPage(dispatch, url);

    const handleSearchOnEnter = (e) => {
      if (e.key === "Enter") {
        navigateToPage(`/search?q=${navSearchText}`);
        toggleHandler();
      }
    };

    return (
      <Fragment>
        <div styleName="overlay" onClick={() => toggleHandler()}></div>
        <ul styleName="dropdown-content" style={{ display: displayStyle }}>
          <div styleName="navbar-top-wrapper">
            <div styleName="hamburger-head">
              <div onClick={() => toggleHandler()}>
                <Link href="/">
                  <img src="/icons/lstlogo.png" styleName="pv-logo" />
                </Link>
              </div>
              <div styleName="hamburger-close">
                <HamburgerMenu
                  isMobileHamburger={false}
                  onMenuToggle={() => toggleHandler()}
                  isMegaMenuOpen={isHamburgerMenuOpen}
                />
              </div>
            </div>
            <div styleName="hamburger-search-box">
              <input
                placeholder="ಹುಡುಕಿ"
                value={navSearchText}
                onChange={(e) => setNavSearchText(e.target.value)}
                onKeyDown={handleSearchOnEnter}
              />
              <Link href={`/search?q=${navSearchText}`}>
                <button
                  aria-label="search-button"
                  styleName="search-box"
                  style={{ margin: "12px" }}
                  type="submit"
                  key="2"
                  onClick={toggleHandler}
                >
                  <div styleName="normal-search">
                    <SvgIconHandler type="search" />
                  </div>
                  <div styleName="hovered-search">
                    <SvgIconHandler type="hoversearch" />
                  </div>
                </button>
              </Link>
            </div>
            <div styleName="buttons-container">
              <HamuburgerEPaperButton />
              {!isUserLogin && isUserLogin !== undefined && isUserLogin !== null ? (
                <div styleName="hamburger-my-account">
                  <HamburgerLoginButton onClick={signinHandler} />
                </div>
              ) : (
                <HamburgerMyAccountButton onAccountHandler={openModal} />
              )}
              <Modal onClose={closeModal} isOpen={showModal == "true"}>
                <MyAccount closeModal={closeModal} />
              </Modal>
            </div>
            {/* <div styleName="buttons-container">
              <a
                href="https://apps.apple.com/in/app/prajavani-kannada-news-app/id1535764933"
                target="_blank"
                rel="noreferrer"
              >
                <HamburgerAppStore />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.tpml.pv&hl=en_IN&gl=US&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <HamburgerGooglePlay />
              </a>
            </div> */}
          </div>
          <div>
            {primaryMenus.length > 0 &&
              primaryMenus.map((item, index) => {
                return (
                  <>
                    <li key={index} styleName="dropdown dropdown-list" onClick={() => changeTextColorHandler(index)}>
                      <MenuItem
                        changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                        menuStyle="menu-link"
                        item={item}
                        toggleHandler={() => toggleHandler()}
                      />
                      {item.children?.length > 0 && (
                        <button
                          aria-label="circle-down-arrow-button"
                          styleName={showPrimaryMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                          type="submit"
                          key="2"
                          onClick={() => showPrimarySectionHandler(index)}
                        >
                          <SvgIconHandler type="circledownarrow" />
                        </button>
                      )}
                    </li>
                    {showPrimaryMenuSection === index && (
                      <div styleName="submenu">
                        {item.children.length > 0 && (
                          <div styleName="ddsection-submenu">
                            <div styleName="ddsec1-submenu">
                              {item.children.slice(0, 10).map((menuItem, index) => (
                                <Link
                                  key={index}
                                  externalLink={menuItem.isExternalLink}
                                  href={menuItem && menuItem?.url}
                                  onClick={toggleHandler}
                                >
                                  <li
                                    styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                    style={{
                                      fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                    }}
                                    onClick={() => {
                                      toggleHandler();
                                      changeTextColorHandler(index);
                                    }}
                                  >
                                    {menuItem.title}
                                  </li>
                                </Link>
                              ))}
                            </div>

                            {item.children.length >= 10 && (
                              <div styleName="ddsec2-submenu">
                                {item.children.slice(10, 21).map((menuItem, index) => (
                                  <Link
                                    key={index}
                                    externalLink={menuItem.isExternalLink}
                                    href={menuItem && menuItem?.url}
                                    onClick={toggleHandler}
                                  >
                                    <li
                                      styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                      style={{
                                        fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                      }}
                                      onClick={() => {
                                        toggleHandler();
                                        changeTextColorHandler(index);
                                      }}
                                    >
                                      {menuItem.title}
                                    </li>
                                  </Link>
                                ))}
                              </div>
                            )}
                            {item.children.length >= 21 && (
                              <div styleName="ddsec3-submenu">
                                {item.children.slice(21, 32).map((menuItem, index) => (
                                  <Link
                                    key={index}
                                    externalLink={menuItem.isExternalLink}
                                    href={menuItem && menuItem?.url}
                                    onClick={toggleHandler}
                                  >
                                    <li
                                      styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                      style={{
                                        fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                      }}
                                      onClick={() => {
                                        toggleHandler();
                                        changeTextColorHandler(index);
                                      }}
                                    >
                                      {menuItem.title}
                                    </li>
                                  </Link>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            {secondaryMenus.length > 0 &&
              secondaryMenus.map((item, index) => {
                return (
                  <>
                    <li
                      id="secondaryMenus"
                      key={index}
                      styleName="dropdown dropdown-list"
                      onClick={() => changeTextColorHandler(index)}
                    >
                      <MenuItem
                        changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                        menuStyle="menu-link"
                        item={item}
                        toggleHandler={() => toggleHandler()}
                      />
                      {item.children?.length > 0 && (
                        <button
                          aria-label="circle-down-arrow-button"
                          styleName={showSecondaryMenuSection === index ? "down-arrow-rotate down-arrow" : "down-arrow"}
                          type="submit"
                          key="2"
                          onClick={() => showSecondarySectionHandler(index)}
                        >
                          <SvgIconHandler type="circledownarrow" />
                        </button>
                      )}
                    </li>
                    {showSecondaryMenuSection === index && (
                      <div styleName="submenu">
                        {item.children.length > 0 &&
                          item.children.map((menuItem, index) => (
                            <Link
                              key={index}
                              externalLink={menuItem.isExternalLink}
                              href={menuItem && menuItem?.url}
                              onClick={toggleHandler}
                            >
                              <li
                                styleName={changeTextColor === index ? "navbar-li_active" : ""}
                                style={{
                                  fontWeight: `${menuItem?.data?.color.toLowerCase() !== "#ffffff" ? "bold" : ""}`
                                }}
                                onClick={() => {
                                  toggleHandler();
                                  changeTextColorHandler(index);
                                }}
                              >
                                {menuItem.title}
                              </li>
                            </Link>
                          ))}
                      </div>
                    )}
                  </>
                );
              })}
          </div>
        </ul>
      </Fragment>
    );
  };

  const navRef = useRef();
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", throttle(getScroll, 250));
    return () => {
      window.removeEventListener("scroll", throttle(getScroll, 250));
    };
  }, []);

  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 3 * navRef.current?.offsetTop) {
        setisActive(true);
      }
      if (window.scrollY < 170) {
        setisActive(false);
      }
    }
  };

  return (
    <div id="sticky-navbar">
      <div styleName="primary-menu-wrapper">
        <nav styleName="wrapper">
          {getFirstNavbarMenu(menu)}
          <div styleName="icons-wrapper">
            <SocialShareIcons />
          </div>
        </nav>
      </div>
      <div styleName={isActive ? "alternateDiv-navbar" : ""}></div>
      <div ref={navRef} styleName={isActive ? "sticky-nav main-wrapper" : "main-wrapper"} id="desktop-sticky-header">
        <nav styleName="wrapper">
          {secondaryMenus.length > 0 ? (
            <div styleName="dropdown">
              <HamburgerMenu
                isMobileHamburger={false}
                onMenuToggle={() => toggleHandler()}
                isMegaMenuOpen={isHamburgerMenuOpen}
              />
              {getDropdownList()}
            </div>
          ) : (
            <div />
          )}
          {isActive && (
            <div styleName="sticky-logo">
              <Link href="/">
                <img src="/icons/lstlogo.png" styleName="sticky-pv-logo" />
              </Link>
            </div>
          )}
          {getSecondNavbarMenu()}
          <div styleName="icons-wrapper store">
            {/* <HeaderNotification /> */}
            {!isUserLogin && isUserLogin !== undefined && isUserLogin !== null ? (
              <button onClick={signinHandler} styleName="signin-btn">
                <SvgIconHandler type="myaccount_black" width="17" height="19" />
                Sign in
              </button>
            ) : (
              <MyAccountButton onAccountHandler={openModal} />
            )}
            <Modal onClose={closeModal} isOpen={showModal == "true"}>
              <MyAccount closeModal={closeModal} />
            </Modal>
            {/* <Link
              href="/search"
              id="search button on the desktop header"
              aria-label="search-button"
              styleName="search-box"
              type="submit"
              key="2"
            >
              <div styleName="normal-search">
                <SvgIconHandler type="search" />
              </div>
              <div styleName="hovered-search">
                <SvgIconHandler type="hoversearch" />
              </div>
            </Link>

            {!isActive && (
              <>
                <a
                  href="https://play.google.com/store/apps/details?id=com.tpml.pv&hl=en_IN&gl=US&pli=1"
                  target="_blank"
                  styleName="app-link"
                  rel="noreferrer"
                >
                  <GooglePlay />
                </a>
                <a
                  href="https://apps.apple.com/in/app/prajavani-kannada-news-app/id1535764933"
                  target="_blank"
                  styleName="app-link"
                  rel="noreferrer"
                >
                  <AppStore />
                </a>
              </>
            )} */}
          </div>
        </nav>
      </div>
    </div>
  );
};

export { NavBar };
