import {
  OPEN_HAMBURGER_MENU,
  OPEN_SEARCHBAR,
  MEMBER_UPDATED,
  IS_OPEN_LOGIN_FORM,
  UPDATE_USER,
  UPDATE_COMMENTS_TOKEN,
  ISLOGIN
} from "./actions";

const storyReducerState = {
  moreStories: [],
  makeSecondaryApiCalls: false,
  delay: 1500,
  isMobile: true
};

const userState = {
  user: {},
  commentsToken: ""
};
const storyReducer = (state = storyReducerState, action) => {
  switch (action.type) {
    case "MORE_STORIES":
      return {
        ...state,
        moreStories: action.payload
      };
    case "SET_MAKE_SECONDARY_API_CALLS_TRIGGERED":
      return {
        ...state,
        makeSecondaryApiCalls: action.makeSecondaryApiCalls,
        delay: action.delay,
        isMobile: action.isMobile
      };
    default:
      return state;
  }
};

function hamburgerMenuReducer(state = false, action) {
  switch (action.type) {
    case OPEN_HAMBURGER_MENU:
      return action.isHamburgerMenuOpen;
    default:
      return state;
  }
}

function searchBarReducer(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCHBAR:
      return action.isSearchBarOpen;
    default:
      return state;
  }
}

function memberReducer(state = null, action) {
  switch (action.type) {
    case MEMBER_UPDATED:
      return action.member;

    default:
      return state;
  }
}

function isLoginReducer(state = false, action) {
  switch (action.type) {
    case ISLOGIN:
      return action.payload;

    default:
      return state;
  }
}

function loginReducer(state = false, action) {
  switch (action.type) {
    case IS_OPEN_LOGIN_FORM:
      return action.payload;
    default:
      return state;
  }
}
function userReducer(state = userState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    case UPDATE_COMMENTS_TOKEN:
      return {
        ...state,
        commentsToken: action.payload
      };
    default:
      return state;
  }
}
export const REDUCERS = {
  isHamburgerMenuOpen: hamburgerMenuReducer,
  isSearchBarOpen: searchBarReducer,
  member: memberReducer,
  isLoginOpen: loginReducer,
  storyReducer: storyReducer,
  user: userReducer,
  isLogin: isLoginReducer
};
