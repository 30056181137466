import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, throttle } from "lodash";
import { PvHeader } from "./pv-header";
import { MobileHeader } from "./mobile-header";
import StickyBanner from "../../molecules/sticky-banner";
import { setMakeSecondaryApiCallsTriggered, setUserDetails, setIsLogin } from "../../store/actions";
import { addTokensToLocalStorage, getUserDetails } from "../../helper/utils";
import GoogleOneTapSignInUp from "../../atoms/google-one-tap";
import { GoogleOAuthProvider, googleLogout } from "@react-oauth/google";
// import ElectionFloatingWidget from "../../atoms/election-floating-widget";

const Header = () => {
  const dispatch = useDispatch();
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));

  const makeSecondaryApiCallsDelay = 2750;
  const [makeSecondaryApiCalls, setMakeSecondaryApiCalls] = useState(false);
  const [isMobile, setMobile] = useState(true);
  const [loadOneTap, setGoogleOneTap] = useState(false);
  const GoogleClientId = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "google_client_id"], "")
  );
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) {
        !makeSecondaryApiCalls && setMakeSecondaryApiCalls(true);
      }
    }
  };

  useEffect(() => {
    if (window && window.innerWidth < 1025) {
      setMobile(true);
    } else {
      setMobile(false);
      setMakeSecondaryApiCalls(true);
    }
    if (window) {
      const searchQuery = window.location.search;
      const accessTokenIndex = searchQuery.indexOf("?access_token");
      const output = accessTokenIndex !== -1 ? searchQuery.substring(accessTokenIndex) : searchQuery;
      const searchParams = new URLSearchParams(output);
      const access_token = searchParams.get("access_token");
      const refresh_token = searchParams.get("refresh_token");
      const userLocalStorage = localStorage.getItem("user");
      const stored_access_token = localStorage.getItem("access_token");
      const stored_refresh_token = localStorage.getItem("refresh_token");

      if (stored_refresh_token && stored_access_token) {
        dispatch(setIsLogin(true));
      }
      if (access_token && refresh_token) {
        addTokensToLocalStorage(access_token, refresh_token);
        getUserDetails()
          .then((data) => {
            if (data?.status === 401) {
              // Remove an tokens from localStorage
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");
              localStorage.removeItem("user");
              localStorage.removeItem("commentsToken");

              googleLogout();
              // loggingout user if refresh token expires
              window.location.href = `${ssoEndpoint}/auth/logout?redirectUrl=${window.location.href.split("?")[0]}`;
            } else {
              dispatch(setIsLogin(true));
              dispatch(setUserDetails(data || {}));
              window.localStorage.setItem("user", JSON.stringify(data));

              const searchParam = accessTokenIndex !== -1 ? searchQuery.substring(0, accessTokenIndex) : searchQuery;
              window.history.replaceState({}, "", window.location.pathname + searchParam);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (userLocalStorage && userLocalStorage !== "undefined" && userdetails?.userData === undefined) {
        console.log("userLocalStorage", userLocalStorage);
        if (typeof userLocalStorage === "string" && userLocalStorage.trim() !== "") {
          dispatch(setUserDetails(JSON.parse(userLocalStorage)));
        }
      } else {
        setGoogleOneTap(true);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", throttle(getScroll, 250));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    makeSecondaryApiCalls &&
      dispatch(setMakeSecondaryApiCallsTriggered(makeSecondaryApiCalls, makeSecondaryApiCallsDelay, isMobile));
  }, [makeSecondaryApiCalls]);

  return (
    <GoogleOAuthProvider clientId={GoogleClientId}>
      {loadOneTap && <GoogleOneTapSignInUp />}
      <div className="header-hide-tab">
        <PvHeader />
      </div>
      <div className="header-hide-dektop">
        <MobileHeader />
      </div>

      <StickyBanner />
      {/* <ElectionFloatingWidget /> */}
    </GoogleOAuthProvider>
  );
};

export { Header };
