import get from "lodash/get";
import { format } from "date-fns";
import { kn } from "date-fns/locale";
import useAdType from "./custom-hook/useAdType";

export const getHeadline = (story) => {
  if (!story) {
    return null;
  }

  return get(story, ["alternative", "home", "default", "headline"], story.headline);
};

export const handleTimeFormat = (timestamp) => {
  if (!timestamp) return;
  return format(new Date(timestamp), "d MMMM yyyy, H:mm", { locale: kn }).replace("ಫೆಬ್ರವರಿ", "ಫೆಬ್ರುವರಿ");
};

export const storyPageBodyAd = (story) => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmSource = searchParams.get("utm_source");
  const ele = document.querySelector("#text-element-with-ad p");
  if (ele) {
    const adText = document.createElement("p");
    adText.textContent = "ADVERTISEMENT";
    adText.id = "ad-text";
    ele.appendChild(adText);

    const desktopAd = document.createElement("div");
    desktopAd.id = utmSource === "izooto" ? "div-gpt-ad-1711630758806-0" : "div-gpt-ad-1675837175211-0";
    desktopAd.className = "hide-mobile story-body-ad";

    const mobileAd = document.createElement("div");
    mobileAd.id = utmSource === "izooto" ? "div-gpt-ad-1711631172416-0" : "div-gpt-ad-1675837516069-0";
    mobileAd.className = "hide-desktop story-body-ad";
    ele.append(desktopAd);
    ele.append(mobileAd);
  }
};
export const extractTextFromHtml = (htmlString) => {
  const re = /<[^>]*>/g;
  const text = htmlString.replace(re, "");
  return text.replace(/&nbsp;/g, " ") || "";
};
export function addTokensToLocalStorage(access_token, refresh_token) {
  if (window) {
    localStorage.setItem("access_token", access_token.replaceAll(" ", "+"));
    localStorage.setItem("refresh_token", refresh_token.replaceAll(" ", "+"));
  }
}

export function getUserDetails() {
  const url = "/get-user-details";
  const timestamp = Date.now();
  // Adding a timestamp to the URL to ensure it's always unique and server treats it as new request.
  const newUrl = `${url}?timestamp=${timestamp}`;

  const access_token = localStorage.getItem("access_token");

  if (access_token?.length > 0) {
    return fetch(newUrl, { headers: new Headers({ Authorization: `Bearer ${access_token}` }) })
      .then(async (response) => {
        if (response?.status === 401) {
          return await refreshAccessToken(getUserDetails)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        } else {
          return response.json();
        }
      })
      .then((userdata) => {
        return userdata;
      })
      .catch((err) => {
        return err;
      });
  }
}
export function putUserDetails(data) {
  const url = "/put-user-details";

  const access_token = localStorage.getItem("access_token");

  if (access_token?.length > 0) {
    return fetch(url, {
      headers: new Headers({ Authorization: `Bearer ${access_token}`, "Content-Type": "application/json" }),
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(async (response) => {
        if (response?.status === 401) {
          return await refreshAccessToken(putUserDetails)
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return err;
            });
        } else {
          return response.json();
        }
      })
      .then((userdata) => {
        return userdata;
      })
      .catch((err) => {
        return err;
      });
  }
}

export function refreshAccessToken(callbackFunction) {
  const url = "/refresh-token";

  const refresh_token = localStorage.getItem("refresh_token");

  if (refresh_token?.length > 0) {
    return fetch(url, { method: "POST", headers: new Headers({ Authorization: `Bearer ${refresh_token}` }) })
      .then((response) => {
        if (!response.ok) {
          throw new Error(JSON.stringify({ status: response.status, statusText: response.statusText }));
        } else {
          return response.json();
        }
      })
      .then((tokendata) => {
        addTokensToLocalStorage(tokendata?.["st-access-token"], tokendata?.["st-refresh-token"]);
        callbackFunction();
      })
      .catch((err) => {
        let catchError;
        try {
          //for error thrown if status code is not 200
          catchError = JSON.parse(err?.message);
        } catch (error) {
          catchError = err;
        }
        return catchError;
      });
  }
}

export function generateTokenForCommenting(userObject) {
  return fetch("/generate-comments-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(userObject)
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
}

export function signInUpWithGoogleOneTap(id_token) {
  const signInBody = {
    thirdPartyId: "google",
    clientType: "web",
    oAuthTokens: {
      id_token
    }
  };

  return fetch("/sign-in-with-token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      rid: "thirdpartypasswordless"
    },
    body: JSON.stringify(signInBody)
  })
    .then((response) => response.json())
    .then((tokendata) => {
      addTokensToLocalStorage(tokendata?.["st-access-token"], tokendata?.["st-refresh-token"]);
    })
    .catch((error) => {
      console.log(error);
    });
}
